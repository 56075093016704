<template>
  <div>
    <Modal v-model="modalModifyItem" :title="isAdd?'新增成本项':'编辑成本项'" :mask-closable="false" width="800">
      <Form ref="serviceItemData" id="serviceItemData" :model="serviceItemData" :rules="ruleValidate" label-position="top">
        <Row :gutter="16">
          <i-col span="12">
            <FormItem label="成本项名称" prop="name">
              <Input size="small" v-model="serviceItemData.name" placeholder="请填写成本项名称" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="任务类型" prop="costType">
              <Select v-model="serviceItemData.costType" size="small" placeholder="请选择运维任务类型">
                <Option v-for="item in taskItemTypes" :key="item.value" :value="item.value">{{item.name}}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>

        <Row :gutter="16">
          <i-col span="12">
            <FormItem label="设备类型" prop="devicemodel">
              <Select v-model="serviceItemData.devicemodel" size="small" placeholder="请选择设备类型">
                <Option v-for="item in deviceModelList" :key="item.id" :value="item.id">{{item.name}}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="是否默认采购" prop="defaultSelect">
              <Checkbox v-model="serviceItemData.defaultSelect">默认采购</Checkbox>
            </FormItem>
          </i-col>
        </Row>

        <template v-if="serviceItemData.devicemodel>399&&serviceItemData.devicemodel<500">
          <FormItem label="设备所属线路" prop="defaultSelect">
            <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
              <Checkbox
                  :indeterminate="indeterminate"
                  :value="checkAll"
                  @click.prevent.native="handleCheckAll">全选</Checkbox>
            </div>
            <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
                <Checkbox v-for="asset in productAssetArray" :key="asset.id" :label="asset.id">{{asset.name}}</Checkbox>
            </CheckboxGroup>
          </FormItem>
        </template>

        <FormItem label="成本规则" prop="serviceItemDetailDtoList">
          <Card :bordered="false" class="m-t-5 m-b-10" v-for="(item,index) in serviceRules" :key="index">
            <p slot="title">{{item.companyName}}</p>
            <div slot="extra">
              <a @click="handleEditServiceRule(item,index)">编辑</a>
              |
              <a @click="handleDeleteServiceRule(index)">删除</a>
            </div>
            <Row>
              <i-col span="12"><span class="title">费用：</span>{{item.price}}元</i-col>
              <i-col span="12"><span class="title">单位：</span>{{item.unitName}}</i-col>
            </Row>
            <Row>
              <i-col span="12"><span class="title">开始时间：</span>{{item.startTime}}</i-col>
              <i-col span="12"><span class="title">结束时间：</span>{{item.endTime}}</i-col>
            </Row>
            <Row>
              <i-col span="12"><span class="title">费用类型：</span>{{item.feeTypeName}}</i-col>
              <i-col span="12"><span class="title">质保有效期：</span>{{item.period}}天</i-col>
            </Row>
            <Row>
              <i-col span="12"><span class="title">资产：</span>{{item.assetName}}</i-col>
              <i-col span="12"><span class="title">位置：</span>{{item.positionName}}</i-col>
            </Row>
            <Row>
              <i-col span="12" v-if="serviceItemData.costType===5"><span class="title">分组：</span>{{item.groupName}}</i-col>
            </Row>
          </Card>
        </FormItem>

        <Row>
          <i-col span="12">
            <a @click="handleAddServiceRule"><Icon type="md-add" size="16"/>添加新的成本规则</a>
          </i-col>
          <i-col span="12" class="text-right">
            <!-- <Button type="success" size="small" :disabled="serviceRules.length<1" @click="handleSubmitServiceItem">
              {{leftPageType==='create'?'提交':'保存'}}成本项
            </Button> -->
          </i-col>
        </Row>
      </Form>

      <div slot="footer" >
        <Button class="btn-black m-r-5" style="width:60px" size="small" @click="modalModifyItem = false">取消</Button>
        <Button class="m-l-8" type="success" size="small" @click="handleSubmitServiceRule">保存成本项</Button>
      </div>
    </Modal>

    <modifyServiceRule ref="modifyServiceRule" :companyId="companyId" :publisherId="publisherId" @update-sevice-rule="handleUpdateServiceRule"/>
  </div>
</template>

<script>
import modifyServiceRule from './ModifyServiceRule'

import em from '@/utils/enum'
import { addServiceItem, getServiceItem } from '@/api/msp/serviceitem'
import { getPublisherProductAssetList } from '@/api/product/asset'

export default {
  components: {
    modifyServiceRule
  },
  data () {
    return {
      modalModifyItem: false,
      isAdd: true, // 是否添加操作
      companyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,

      serviceItemData: {
        name: '',
        price: 0,
        unit: 1,
        defaultSelect: true,
        materialId: 0,
        devicemodel: 0,
        costType: 0,
        serviceItemDetailDtoList: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      productAssetArray: [], // 交易资源所属线路集合
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],

      unitList: em.maintenanceUnitEnum,
      serviceRules: [] // 当前服务规则项
    }
  },
  created () {
    this.initBaseData()
  },
  methods: {
    initBaseData () {
      this.$store.dispatch('getDeviceModelData').then(res => {
        this.serviceItemData.devicemodel = res[0].id
      })

      this.$store.dispatch('getTaskItemTypesData').then(res => {
        this.serviceItemData.costType = res[0].value
      })

      getPublisherProductAssetList().then(res => {
        this.productAssetArray = res
      })
    },
    showModal (isAdd) {
      this.isAdd = isAdd
      this.modalModifyItem = true

      if (isAdd) {

      } else {

      }
    },
    handleAddServiceRule () {
      this.$refs.modifyServiceRule.showModal(true, this.serviceItemData.costType)
    },
    handleEditServiceRule (serviceRuleBean, index) {
      this.$refs.modifyServiceRule.showModal(false, this.serviceItemData.costType, serviceRuleBean, index)
    },
    handleUpdateServiceRule (params) {
      if (params.isAdd) {
        this.serviceRules.push(params.serviceRuleBean)
      } else {
        this.serviceRules[params.editIndex] = params.serviceRuleBean
      }
    },
    handleDeleteServiceRule (index) {
      this.serviceRules.splice(index, 1)
    },
    handleSubmitServiceItem () {
      this.$refs.serviceItemData.validate((valid) => {
        if (valid) {
          this.serviceItemData.serviceItemDetailDtoList = this.serviceRules
          const postData = {
            publisherId: this.publisherId,
            serviceitemJson: JSON.stringify(this.serviceItemData)
          }

          addServiceItem(postData).then(res => {
            this.$Notice.success({ desc: '添加成本项成功！' })
            // 刷新底部表单
            this.$store.commit('set_service_config_beginUpdateBottom', new Date())
            // 清单当前数据
            this.resetPage()
          })
        }
      })
    },
    handleSubmitServiceRule () {
      this.serviceRuleBean.companyName = this.maintenanceCompanyList.find(x => x.id === this.serviceRuleBean.companyId).name
      this.serviceRuleBean.feeTypeName = this.feeTypeArray.find(x => x.value === this.serviceRuleBean.feeType).name
      this.serviceRuleBean.unitName = this.unitList.find(x => x.value === this.serviceRuleBean.unit).name
      this.serviceRuleBean.positionName = this.positionArray.find(x => x.value === this.serviceRuleBean.position).name
      this.serviceRuleBean.assetName = this.assetArray.find(x => x.id === this.serviceRuleBean.assetId).name

      if (this.costType === 5 && this.serviceRuleBean.group && this.serviceRuleBean.group !== -1) {
        this.serviceRuleBean.groupName = this.serviceRuleGroups.find(x => x.id === this.serviceRuleBean.group).name
      }

      this.$emit('update-sevice-rule',
        {
          isAdd: this.isAdd,
          editIndex: this.editIndex,
          serviceRuleBean: this.serviceRuleBean
        }
      )

      this.modalModifyServiceRule = false
    },
    handleCheckAll () {
      if (this.indeterminate) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.indeterminate = false

      if (this.checkAll) {
        this.checkAllGroup = this.productAssetArray.map(x => x.id)
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange (data) {
      if (data.length === this.productAssetArray.length) {
        this.indeterminate = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.indeterminate = true
        this.checkAll = false
      } else {
        this.indeterminate = false
        this.checkAll = false
      }
    }
  },
  computed: {
    deviceModelList () { // 设备类型清单
      return this.$store.state.serviceConfig.deviceModelListData
    },
    taskItemTypes () { // 任务类型
      return this.$store.state.serviceConfig.taskItemTypesData
    }
  },
  watch: {
    beginUpdateLeft () {
      // if (this.leftPageType === 'create') {
      //   this.resetPage()
      // } else if (this.leftPageType === 'edit') {
      // 获取当前配置项的信息
      getServiceItem({ serviceItemId: this.editId }).then(res => {
        this.serviceItemData = res
        this.serviceRules = res.serviceItemDetailDtoList
      })
      // }
    }
  }
}
</script>
